import React from 'react';
import './CareersStyle.css';

const Carrer = () => {
  return (
    <div className="car88-container">
      {/* Description Section */}
      <div className="car88-description">
        <h2 className="car88-heading">Join Our Team at Devon Technology</h2>
        <p className="car88-text">
          At Devon Technology, we are dedicated to providing top-notch technology solutions to our clients worldwide. We pride ourselves on fostering an inclusive and innovative work environment where our employees can grow and thrive. Our team of experts collaborates to solve complex business challenges, helping organizations to optimize their operations and achieve their goals.
          <br /><br />
          We offer exciting career opportunities for professionals who are passionate about technology and eager to make a difference. If you are looking to join a dynamic team that values creativity, excellence, and continuous learning, we would love to hear from you.
        </p>
      </div>

      {/* Image Section */}
      <div className="car88-image-section">
        <img
          src="https://images.pexels.com/photos/3184325/pexels-photo-3184325.jpeg?auto=compress&cs=tinysrgb&w=1600"
          alt="IT Consultancy"
          className="car88-image"
        />
      </div>
    </div>
  );
}

export default Carrer;
