import React from 'react';
import './JavaStyle.css';

const Java = () => {
  return (
    <div className="java-containerjava22">
      {/* Section 1: Introduction */}
      <div className="section1java22">
        <div className="image-container1java22">
          <img
            src="https://img.freepik.com/premium-photo/software-developing-programming-coding-technologies-website-design-cyber-space-concept_1288657-70.jpg?w=900"
            alt="Java Consulting"
            className="image1java22"
          />
        </div>
        <div className="text-container1java22">
          <h1 className="title1java22">
            Professional <span className="bluejava22">Java Consulting</span> Services
          </h1>
          <p className="description1java22">
            At Devon Technologies, we provide top-notch Java consulting services to help businesses leverage the power of Java for their enterprise solutions. Our expert team ensures that your Java projects are aligned with your business goals, offering scalable and efficient software solutions.
          </p>
          <p className="additional-info1java22">
            Whether you are looking to develop new Java applications or optimize existing ones, our consulting services are designed to maximize your ROI and enhance your IT infrastructure.
          </p>
        </div>
      </div>

      {/* Section 2: Custom Java Solutions */}
      <div className="section2java22">
        <div className="text-container2java22">
          <h2 className="sub-title2java22">
            Tailored <span className="bluejava22">Java Solutions</span> for Your Business
          </h2>
          <p className="description2java22">
            Our team specializes in creating custom Java solutions that are designed to meet the unique needs of your business. We develop robust, high-performance Java applications that drive efficiency and innovation across your organization.
          </p>
          <p className="additional-info2java22">
            From backend systems to enterprise-level applications, we use the latest Java technologies and frameworks to deliver solutions that enhance your business operations and provide a competitive edge.
          </p>
        </div>
        <div className="image-container2java22">
          <img
            src="https://st2.depositphotos.com/4021139/7527/i/450/depositphotos_75272709-stock-photo-java-concept.jpg"
            alt="Custom Java Solutions"
            className="image2java22"
          />
        </div>
      </div>

      {/* Section 3: Why Java? */}
      <div className="section3java22">
        <div className="text-container3java22">
          <h2 className="sub-title3java22">
            Why <span className="bluejava22">Java</span> for Your Business?
          </h2>
          <p className="description3java22">
            Java is a versatile and powerful programming language that is widely used in enterprise environments. Its platform independence, scalability, and security make it an ideal choice for developing a wide range of business applications.
          </p>
          <p className="additional-info3java22">
            By choosing Java, you can ensure that your applications are future-proof, maintainable, and capable of handling large-scale operations. Our team has extensive experience in Java and is committed to delivering solutions that meet the highest standards of quality and performance.
          </p>
        </div>
        <div className="image-container3java22">
          <img
            src="https://c0.wallpaperflare.com/preview/600/243/618/javascript-vscode-editor-code.jpg"
            alt="Java Benefits"
            className="image3java22"
          />
        </div>
      </div>

      {/* Section 4: Our Java Expertise */}
      <div className="section4java22">
        <div className="image-container4java22">
          <img
            src="https://c4.wallpaperflare.com/wallpaper/637/317/264/java-programming-programming-language-computer-wallpaper-preview.jpg"
            alt="Java Expertise"
            className="image4java22"
          />
        </div>
        <div className="text-container4java22">
          <h2 className="sub-title4java22">
            Our <span className="bluejava22">Java Expertise</span>
          </h2>
          <p className="description4java22">
            With years of experience in Java development, our team has deep knowledge of Java's core features and frameworks. We are experts in building complex, scalable, and secure applications that meet the specific needs of our clients.
          </p>
          <p className="additional-info4java22">
            Our expertise extends across various industries, allowing us to deliver tailored Java solutions that help businesses streamline operations, reduce costs, and improve overall efficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Java;
