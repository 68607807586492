import React from 'react';
import './TrustSolStyle.css';

const TrustSol = () => {
  return (
    <div className="containerTrs">
      {/* Homepage Image Section */}
      <div className="homepage-image-sectionTrs">
        <img 
          src="https://images.pexels.com/photos/114907/pexels-photo-114907.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
          alt="Homepage" 
          className="homepage-imageTrs" 
        />
      </div>

      {/* Introduction Section */}
      <section className="intro-sectionTrs">
        <h1 className="main-headingTrs">
          <span className="black-textTrs">Your Trusted </span>
          <span className="blue-textTrs">Digital Transformation </span>
          <span className="black-textTrs">Solution Partner.</span>
        </h1>
        <div className="content-sectionTrs">
          <p className="left-textTrs">
          At Devon Technologies, we specialize in delivering tailored SAP services, harnessing the power of industry-leading software to optimize your business operations and drive growth. Our expert team is dedicated to providing innovative solutions that are not only aligned with your strategic goals but also adaptable to the ever-evolving business landscape.          </p>
          
          <div className="image-sectionTrs">
            <img 
              src="https://wallpapers.com/images/hd/4k-tech-twtykjtj0sv4hi62.jpg" 
              alt="Digital Transformation" 
              className="imageTrs" 
            />
          </div>
        </div>
      </section>

      {/* SAP Section */}
      <section className="sap-sectionTrs">
        <h2 className="sap-headingTrs">SAP S/4 HANA Solutions</h2>
        <p className="subheadingTrs">Stay Ahead of the Competition</p>
        <div className="logos-rowTrs">
          <div className="logo-itemTrs">
            <img 
              src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/Implementation1.png" 
              alt="Implementation" 
              className="logoTrs" 
            />
            <p>Implementation</p>
          </div>
          <div className="logo-itemTrs">
            <img 
              src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/Migration.png" 
              alt="Migration" 
              className="logoTrs" 
            />
            <p>Migration</p>
          </div>
          <div className="logo-itemTrs">
            <img 
              src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/Conversion.png" 
              alt="Conversion" 
              className="logoTrs" 
            />
            <p>Conversion</p>
          </div>
          <div className="logo-itemTrs">
            <img 
              src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/Integration.png" 
              alt="Integration" 
              className="logoTrs" 
            />
            <p>Integration</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TrustSol;
