import React from 'react';
import './ConsultingStyle.css';

const Consulting = () => {
  return (
    <div className="consulting-containerConc">
      {/* Section 1: Introduction */}
      <div className="section1Conc">
        <div className="image-container1Conc">
          <img
            src="https://images.pexels.com/photos/4101143/pexels-photo-4101143.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Consulting Services"
            className="image1Conc"
          />
        </div>
        <div className="text-container1Conc">
          <h1 className="title1Conc">
            Comprehensive <span className="blueConc">Consulting & Outsourcing</span> Services for IT Professionals
          </h1>
          <p className="description1Conc">
            At Devon Technologies, we provide specialized consulting and outsourcing services tailored for IT professionals and job seekers. Our expert team works closely with candidates to enhance their career prospects and help them achieve their professional goals.
          </p>
          <p className="additional-info1Conc">
            Whether you're looking for guidance in job search, skill development, or career advancement, our services are designed to meet your unique needs. We offer flexible, scalable solutions that support your career growth and help you stay competitive in the job market.
          </p>
        </div>
      </div>

      {/* Section 2: Consulting Services */}
      <div className="section2Conc">
        <div className="text-container2Conc">
          <h2 className="sub-title2Conc">
            <span className="blueConc">Consulting</span> Services for Job Seekers
          </h2>
          <p className="description2Conc">
            Our consulting services are focused on empowering job seekers to navigate the IT job market successfully. From resume building to interview preparation, our consultants offer expert advice and resources to help you secure the right job.
          </p>
          <p className="additional-info2Conc">
            We specialize in areas such as skill assessment, career counseling, and job search strategies. Our goal is to equip you with the tools and knowledge needed to excel in your job search and achieve your career aspirations.
          </p>
        </div>
        <div className="image-container2Conc">
          <img
            src="https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241213.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
            alt="Outsourcing Services"
            className="image2Conc"
          />
        </div>
      </div>

      {/* Section 3: Outsourcing Services */}
      <div className="section3Conc">
        <div className="text-container3Conc">
          <h2 className="sub-title3Conc">
            <span className="blueConc">Outsourcing</span> Services for IT Employees
          </h2>
          <p className="description3Conc">
            Our outsourcing services are designed to assist IT employees in managing their workload more effectively. We offer a range of services, including project management, skill enhancement, and remote work support, all delivered by a team of experienced professionals.
          </p>
          <p className="additional-info3Conc">
            By outsourcing certain tasks to Devon Technologies, you can focus on your core responsibilities while we handle the rest. Our outsourcing solutions are tailored to meet your specific needs, ensuring that you have the support required to succeed in your role.
          </p>
        </div>

        <div className="image-container3Conc">
          <img
            src="https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241233.jpg?ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
            alt="IT Outsourcing"
            className="image3Conc"
          />
        </div>

      </div>
    </div>
  );
};

export default Consulting;
