import React from 'react'
import "./CustomersStyle.css"

const Customers = () => {
  return (
    <div>
      {/* Statistics Section */}
      <div className="sap55-stats">
        <div className="sap55-stat-item">
          <div className="sap55-stat-number">90%</div>
          <div className="sap55-stat-description">German Customers</div>
        </div>
        <div className="sap55-stat-item">
          <div className="sap55-stat-number">60,000</div>
          <div className="sap55-stat-description">Satisfied Users Globally</div>
        </div>
        <div className="sap55-stat-item">
          <div className="sap55-stat-number">80%</div>
          <div className="sap55-stat-description">Certified Resources</div>
        </div>
        <div className="sap55-stat-item">
          <div className="sap55-stat-number">55+</div>
          <div className="sap55-stat-description">Active Customers</div>
        </div>
      </div>

      <div className="sap55-divider"></div>
    </div>
  
  )
}

export default Customers
