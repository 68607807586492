import React, { useState } from 'react';
import './HeaderStyle.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header-containerDevH ${menuOpen ? 'active' : ''}`}>
      <div className="logo-containerDevH">
        <a href="/" className="logo-linkDevH">
          {/* <h1>Devon Tech</h1> */}
          <img src="https://i.postimg.cc/W3cPL0GP/Devon.png" alt="Logo" className="logoDevH" />
        </a>
      </div>
      <div className="hamburgerDevH" onClick={toggleMenu}>
        <div className="hamburger-lineDevH"></div>
        <div className="hamburger-lineDevH"></div>
        <div className="hamburger-lineDevH"></div>
      </div>
      <nav className={`navDevH ${menuOpen ? 'open' : ''}`}>
        <span className="cancel-iconDevH" onClick={toggleMenu}>&times;</span>
        <ul className="nav-listDevH">
          <li className="nav-itemDevH">
            <a href="/Services" className="nav-linkDevH">Services</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="#">SAP IMPLEMENTATION</a></li>
              <li className="dropdown-itemDevH"><a href="#">SAP GLOBAL ROLLOUT SERVICES</a></li>
              <li className="dropdown-itemDevH"><a href="#">SAP MANAGED SERVICES</a></li>
              <li className="dropdown-itemDevH"><a href="#">S/4HANA READINESS</a></li>
              <li className="dropdown-itemDevH"><a href="#">S/4HANA MIGRATION</a></li>
              <li className="dropdown-itemDevH"><a href="#">SAP DATA MANAGEMENT</a></li>
            </ul>
          </li>
          <li className="nav-itemDevH">
            <a href="About" className="nav-linkDevH">About Us</a>
            {/* <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="/About">About Us</a></li>
            </ul> */}
          </li>
          {/* <li className="nav-itemDevH">
            <a href="#" className="nav-linkDevH">Information Lounge</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="#">BLOG</a></li>
              <li className="dropdown-itemDevH"><a href="#">INTRODUCTION TO SAP S/4 HANA</a></li>
              <li className="dropdown-itemDevH"><a href="#">CRITICAL FACTORS FOR S/4 HANA</a></li>
              <li className="dropdown-itemDevH"><a href="#">ONSITE AND OFFSHORE ENGAGEMENT MODEL</a></li>
            </ul>
          </li> */}
          <li className="nav-itemDevH">
            <a href="/Careers" className="nav-linkDevH">Career</a>
          </li>
          <li className="nav-itemDevH">
            <a href="/Contactus" className="nav-linkDevH">Contact Us</a>
          </li>
        </ul>
      </nav>
      <div className={`sidebar-overlayDevH ${menuOpen ? 'visible' : ''}`} onClick={toggleMenu}></div>
    </header>
  );
};

export default Header;
