import React from 'react';
import './AboutStyle.css';

const About = () => {
  return (
    <div className="about-containerAbout">
      <div className="about-text-sectionAbout">
        <h1 className="about-titleAbout">
          Devon <span className="about-highlightAbout">Technologies</span> India Private Limited 
        </h1>
        <p className="about-descriptionAbout">
          Devon Technologies India Private Limited is an IT services company offering services in application development, 
          enterprise solutions, and consulting services. Devon Technologies is a global customer experience and technology 
          services provider disrupting the industry with a unique intersection of industry-leading proprietary solutions, 
          resilient operations, and innovative business engagement models.
        </p>
        <p className="about-subtextAbout">
          The company is a digital transformation partner of choice for its clients, which include the world’s top innovators across industries.
        </p>
        <button className="about-read-moreAbout">Read More</button>
      </div>
      <div className="about-image-sectionAbout">
        <img 
          src="https://burst.shopifycdn.com/photos/tech-group-meeting-flatlay.jpg?width=1200&format=pjpg&exif=1&iptc=1" 
          alt="Devon Technologies" 
          className="about-imageAbout"
        />
      </div>
    </div>
  );
};

export default About;
