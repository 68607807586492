import React from 'react';
import './FooterStyle.css';

const Footer = () => {
  return (
    <footer className="footer-containerDevFtr">
      <div className="footer-columnDevFtr">
    
        <img src="https://i.postimg.cc/W3cPL0GP/Devon.png"  style={{marginTop:"-30px"}} alt="Logo" className="footer-logoDevFtr" />
        <p className="footer-textDevFtr">
          Devon Technologies the best talent in the company, we offer you genuine affordable services with unconditional customer-centric approach.
        </p>
      </div>

      <div className="footer-columnDevFtr">
        <h3 className="footer-headingDevFtr">Services</h3>
        <ul className="footer-services-listDevFtr">
         
          <li>★ Consulting & Outsourcing</li>
          <li>★ Software Development</li>
          <li>★ Java</li>
          <li>★ Testing</li>
          <li>★ Full-Stack</li>
        </ul>
      </div>

      <div className="footer-columnDevFtr">
        <h3 className="footer-headingDevFtr">Technology</h3>
        <ul className="footer-tech-listDevFtr">
          <li>★ Sap Transformation</li>
          <li>★ Cloud Engineering</li>
          <li>★ Application Development</li>
          {/* <li>Full Stack</li> */}
        </ul>
      </div>

      <div className="footer-columnDevFtr">
      <img src="https://i.postimg.cc/bwdPQyy4/Devon-logo-2.png" alt="Logo" className="footer-logoDevFtr" />
        <h3 className="footer-headingDevFtr" style={{marginTop:"-20px"}}>Address</h3>
        <p className="footer-addressDevFtr">
          <span className="footer-location-iconDevFtr">📍</span> 
          # 3rd floor, Lumbini Society, Survey No. 55, Plot No. 108,
            NYN Arcade off Hitech City Main Road, next to Euro School, Gachibowli, Hyderabad, Telangana 500032.
        </p>
        <p className="footer-emailDevFtr">
          <span className="footer-message-iconDevFtr">✉️</span> 
          hr@devontech.in
        </p>
      </div>
    </footer>
  );
};

export default Footer;
