import React from 'react';
import './CloudStyle.css'; // Import the CSS file
import Lower from './Lower';
import Customers from './Customers';

const Cloud = () => {
  return (
    <div className="cloud-container">
      {/* Home Banner Image */}
      <div className="cloud-banner">
        <img
          src="https://img.freepik.com/premium-photo/futuristic-aidriven-cloud-optimization_992795-7227.jpg?w=826"
          alt="Home Banner"
          className="cloud-banner-img"
        />
      </div>

      <Customers />

      {/* Cloud Computing Section */}
      <div className="cloud-computing">
        <div className="cloud-computing-text">
          <h2 className="cloud-computing-heading">
            Unlock the potential of <span className="cloud-computing-blue">Cloud Computing</span>
          </h2>
          <p className="cloud-computing-description">
            The cloud offers businesses a path to agility, scalability, and cost-effectiveness. But before migrating your IT infrastructure, a thorough cloud assessment is crucial. At Devon Technologies, we help you navigate the cloud journey with confidence.
          </p>
        </div>
        <div className="cloud-computing-image">
          <img
            src="https://img.freepik.com/premium-photo/cloud-computing-cost-efficiency-cost-efficiency-cloud-computing-with-image-showing-payperus_339391-30069.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
            alt="Cloud Computing"
            className="cloud-computing-img"
          />
        </div>
      </div>

      {/* Services Section */}
      <div className="cloud-services">
        <h2 className="cloud-services-heading">Devon partners with the customers in their entire Cloud migration journey covering:</h2>
        <div className="cloud-services-cards">
          {/* Card 1 */}
          <div className="cloud-card">
            <img
              src="https://img.freepik.com/premium-photo/abstract-computer-circuit-with-cloud-computing-technology_1261838-4194.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
              alt="Cloud Readiness Assessment"
              className="cloud-card-img"
            />
            <div className="cloud-card-text">
              <h4>Cloud Readiness Assessment</h4>
              <p>
                The first and important step of migration of your on-premise assets to cloud is to conduct a thorough readiness assessment. This is not only about technology but also about ensuring that the organization’s requirements, capabilities, and strategic objectives are considered before planning the migration.
              </p>
              <button className="cloud-card-button">Read More</button>
            </div>
          </div>
          {/* Card 2 */}
          <div className="cloud-card">
            <img
              src="https://img.freepik.com/free-photo/woman-scrolling-laptop_53876-167050.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
              alt="Cloud Migration Planning"
              className="cloud-card-img"
            />
            <div className="cloud-card-text">
              <h4>Cloud Migration Planning</h4>
              <p>
                Based on your goals, we create a customized migration plan that minimizes disruption and ensures success.
              </p>
              <button className="cloud-card-button">Read More</button>
            </div>
          </div>
          {/* Card 3 */}
          <div className="cloud-card">
            <img
              src="https://img.freepik.com/premium-photo/cloud-computing-data-security-illustration_191095-52212.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
              alt="Cloud Migration"
              className="cloud-card-img"
            />
            <div className="cloud-card-text">
              <h4>Cloud Migration</h4>
              <p>
                Our team focuses on security throughout the process. We assess authentication, encryption, access controls, and data protection measures. Additionally, we evaluate the provider's ability to scale to meet your future needs.
              </p>
              <button className="cloud-card-button">Read Moree</button>
            </div>
          </div>
          {/* Card 4 */}
          <div className="cloud-card">
            <img
              src="https://img.freepik.com/free-vector/computer-cloud-network-technology_53876-100678.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
              alt="Cloud Support"
              className="cloud-card-img"
            />
            <div className="cloud-card-text">
              <h4>Cloud Support</h4>
              <p>
                Proven methodologies and tools are used to monitor your cloud environment after migration, ensuring ongoing security, efficiency, and cost-effectiveness.
              </p>
              <button className="cloud-card-button">Read More</button>
            </div>
          </div>
        </div>
      </div>
      <Lower />
    </div>
  );
};

export default Cloud;
