import React from 'react';
import './DevelopmentStyle.css';

const Development = () => {
  return (
    <div className="development-containerSoft">
      {/* Section 1: Introduction */}
      <div className="section1Soft">
        <div className="image-container1Soft">
          <img
            src="https://images.pexels.com/photos/4974912/pexels-photo-4974912.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Software Development"
            className="image1Soft"
          />
        </div>
        <div className="text-container1Soft">
          <h1 className="title1Soft">
            Cutting-Edge <span className="blueSoft">Software Development</span> Services
          </h1>
          <p className="description1Soft">
            At Devon Technologies, we offer a full spectrum of software development services designed to meet the needs of modern businesses. Our expert team is skilled in the latest technologies and methodologies, ensuring that your projects are delivered on time and to the highest standards.
          </p>
          <p className="additional-info1Soft">
            From initial consultation to final deployment, we work closely with our clients to develop custom software solutions that drive business success. Whether you're looking to build a new application or enhance an existing system, our team has the expertise to help you achieve your goals.
          </p>
        </div>
      </div>

      {/* Section 2: Our Services */}
      <div className="section2Soft">
        <div className="text-container2Soft">
          <h2 className="sub-title2Soft">
            Our <span className="blueSoft">Software Development</span> Services
          </h2>
          <p className="description2Soft">
            We specialize in a wide range of software development services, including web and mobile application development, cloud solutions, and enterprise software. Our approach is tailored to your specific needs, ensuring that you get the most value from our services.
          </p>
          <p className="additional-info2Soft">
            Our development process is agile and transparent, allowing us to adapt to changes quickly and keep you informed at every step. Whether you're a startup or an established enterprise, we have the skills and experience to help you succeed in the digital world.
          </p>
        </div>
        <div className="image-container2Soft">
          <img
            src="https://img.freepik.com/premium-photo/it-engineer-working-with-his-pc_1261838-11843.jpg?w=740"
            alt="Development Services"
            className="image2Soft"
          />
        </div>
      </div>

      {/* Section 3: Why Choose Us */}
      <div className="section3Soft">
        <div className="text-container3Soft">
          <h2 className="sub-title3Soft">
            Why Choose <span className="blueSoft">Devon Technologies?</span>
          </h2>
          <p className="description3Soft">
            Our commitment to quality and customer satisfaction sets us apart from the competition. We leverage the latest technologies and best practices to deliver innovative solutions that exceed expectations.
          </p>
          <p className="additional-info3Soft">
            By partnering with Devon Technologies, you gain access to a team of experts who are passionate about software development and dedicated to your success. Let us help you transform your ideas into reality with our comprehensive development services.
          </p>
        </div>
        <div className="image-container3Soft">
          <img
            src="https://img.freepik.com/premium-photo/poster-people-working-meeting-room-with-computer-man-working-it_655090-3225102.jpg?w=826"
            alt="Why Choose Us"
            className="image3Soft"
          />
        </div>
      </div>

      {/* Section 4: Our Expertise */}
      <div className="section4Soft">
        <div className="image-container4Soft">
          <img
            src="https://img.freepik.com/premium-photo/developers-project-grind_1275912-65343.jpg?w=740"
            alt="Our Expertise"
            className="image4Soft"
          />
        </div>
        <div className="text-container4Soft">
          <h2 className="sub-title4Soft">
            Our <span className="blueSoft">Expertise</span>
          </h2>
          <p className="description4Soft">
            Devon Technologies brings a wealth of expertise to the table, with deep knowledge in software architecture, cloud computing, DevOps, and more. Our team is constantly learning and evolving, ensuring that we stay ahead of industry trends and deliver cutting-edge solutions to our clients.
          </p>
          <p className="additional-info4Soft">
            We have successfully delivered projects across various industries, including finance, healthcare, retail, and manufacturing. Our experience and technical prowess enable us to tackle the most complex software development challenges with confidence and precision.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Development;
