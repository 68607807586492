import React from "react";
import "./Application.Style.css";
import Customers from "./Customers";
import Lower from "./Lower";
import Tstack from "./Tstack";

const Application = () => {
  return (
    <div className="app21-container">
      {/* ==================== Home Image Section ==================== */}
      <div className="app21-home-img">
        <img
          src="https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=1600"
          alt="Home"
          className="app21-home-img-src"
        />
      </div>

    <div className="customers88">  <Customers/></div>

      {/* ==================== Main Heading Section ==================== */}
      <div className="app21-heading-section">
        <h1 className="app21-heading">
          AUTOMATIC: Your reliable partner for{" "}
          <span className="app21-blue-text">Digital Transformation Services</span>
        </h1>
        <p className="app21-subtext">
          DEVON is your premier destination for professional, high-quality, and cost-effective
          digital transformation services covering your entire Application ecosystem. With our
          extensive experience and expertise across multiple industry domains and technology
          platforms, we offer a comprehensive suite of services including application development,
          enhancement, maintenance, migration, and testing to organizations worldwide.
        </p>
      </div>

      {/* ==================== Digital Transformation Services Section ==================== */}
      <div className="app21-services-section">
        {/* Section Heading */}
        <h2 className="app21-services-heading">
          <span className="app21-blue-text">Digital Transformation</span> Services
        </h2>
        {/* Section Subtext */}
        <p className="app21-services-subtext">
          We are aligned with your Digital transformation vision and partner with you with the
          following services:
        </p>

        {/* ==================== Services Cards Section ==================== */}
        <div className="app21-cards">
          {/* ---------- First Card: Application Development ---------- */}
          <div className="app21-card">
            {/* Card Image */}
            <img
              src="https://images.pexels.com/photos/3184633/pexels-photo-3184633.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt="Application Development"
              className="app21-card-img"
            />
            {/* Card Heading */}
            <h3 className="app21-card-heading">Application Development</h3>
            {/* Card Description */}
            <p className="app21-card-description">
              • Development of customized applications based on your requirements
              <br />• Interfaced with your central SAP / ERP system
              <br />• Automation of business processes
            </p>
          </div>

          {/* ---------- Second Card: Application Migration ---------- */}
          <div className="app21-card">
            {/* Card Image */}
            <img
              src="https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt="Application Migration"
              className="app21-card-img"
            />
            {/* Card Heading */}
            <h3 className="app21-card-heading">Application Migration</h3>
            {/* Card Description */}
            <p className="app21-card-description">
              • Migration of old applications on modern technology architecture
              <br />• Consolidation of application landscape
              <br />• Deployment of industry best practices to ensure your applications ecosystem is
              modernized
            </p>
          </div>

          {/* ---------- Third Card: Analytics ---------- */}
          <div className="app21-card">
            {/* Card Image */}
            <img
              src="https://images.pexels.com/photos/3183171/pexels-photo-3183171.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt="Analytics"
              className="app21-card-img"
            />
            {/* Card Heading */}
            <h3 className="app21-card-heading">Analytics</h3>
            {/* Card Description */}
            <p className="app21-card-description">
              • Implementation
              <br />• Data modelling and transformation
              <br />• Reports / Dashboard developments
              <br />• Performance tuning
              <br />• Monitoring
            </p>
          </div>
        </div>
      </div>

      <Tstack/>

      {/* ==================== Why Partner with Automatic Section ==================== */}
      <div className="app21-why-partner-section">
        {/* Section Heading */}
        <h2 className="app21-why-heading">
          Why partner with DEVON for your{" "}
          <span className="app21-blue-text">digital transformation journey?</span>
        </h2>
        {/* Reasons List */}
        <ul className="app21-why-list">
          <li>
            ★ <strong>Industry expertise:</strong> Our team brings a wealth of experience and
            knowledge across various industry domains, enabling us to deliver tailored solutions
            that meet the unique requirements of your business.
          </li>
          <li>
            ★ <strong>Proven Methodologies:</strong> We follow rigorous Software Development Life
            Cycle (SDLC) processes, ensuring that every stage of the development process is
            meticulously planned, executed, and monitored to deliver timely, cost-effective, and
            high-quality solutions.
          </li>
          <li>
            ★ <strong>Customized Solutions:</strong> Whether you need a new application platform
            developed from scratch, enhancements to an existing application, or migration services,
            we provide customized solutions that align with your business goals and objectives.
          </li>
          <li>
            ★ <strong>Comprehensive Services:</strong> Our application development services
            encompass a wide range of functionalities including interface development, defect
            correction, functionality extensions, GUI enhancements, and application optimization for
            improved performance.
          </li>
        </ul>
      </div>
      <Lower/>
    </div>
  );
};

export default Application;
