import React, { useState } from 'react';
import './TstackStyle.css'; // Import the CSS file

const Tstack = () => {
  // State to manage which category is active, defaulting to 'Database Systems'
  const [activeCategory, setActiveCategory] = useState('Database Systems'); 

  // Data for each category with corresponding images
  const categories = {
    'Database Systems': [
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/Oracle-Logo-2048x1152.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/5968364.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/Microsoft_Office_Access_2019-present.svg.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/Mysql_logo.png',
    ],
    'Development Frameworks': [
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/microsoft-net-logo-631EFE744A-seeklogo.com_.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/J2EE.webp',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/Eclipse-Luna-Logo.svg.png',
    ],
    'Technologies': [
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/c-sharp-c-logo-02F17714BA-seeklogo.com_-1.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/kindpng_131176.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/Microsoft-dot-net-logo.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/DHTML-Logo-1.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/VBScript.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/HTML5_Logo_512.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/xml-logo-58BFD10607-seeklogo.com_.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/CSS3_logo_and_wordmark.svg.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/AJAX_logo_by_gengns.svg.png',
    ],
    'Analytics': [
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/SAP-BW-.webp',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/sap-bi-logo-B8B0022BA9-seeklogo.com_.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/SAP-Business-Objects.png',
      'https://www.automaticinfotech.com/wp-content/uploads/2024/05/PowerBI.png',
    ],
  };

  return (
    <div className="tstack-containerStack5">
      {/* Header Section */}
      <h1 className="tstack-headerStack5">
        <span className="tstack-header-greenStack5">Technology</span> stack
      </h1>
      <p className="tstack-subtextStack5">
        We have proven expertise on the modern technology stack comprising of:
      </p>

      {/* Main Box Section */}
      <div className="tstack-main-boxStack5">
        <div className="tstack-categories-rowStack5">
          {/* Category Tabs */}
          {Object.keys(categories).map((category) => (
            <div
              key={category}
              className={`tstack-categoryStack5 ${
                activeCategory === category ? 'tstack-activeStack5' : ''
              }`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </div>
          ))}
        </div>

        {/* Images Display Section */}
        {activeCategory && (
          <div className="tstack-images-containerStack5">
            {categories[activeCategory].map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${activeCategory} ${index + 1}`}
                className="tstack-imageStack5"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tstack;
