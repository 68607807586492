import React from 'react';
import './SaptransStyle.css';
import Lower from './Lower';
import Customers from './Customers';

const Saptrans = () => {
  
  return (
    <div className="sap55-container">
      {/* Home Banner Image */}
      <div className="sap55-banner">
        <img
          src="https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Home Banner"
          className="sap55-banner-img"
        />
      </div>

     <Customers/>

     {/* Main Text Section */}
<div className="sap55-main-section">
  <div className="sap55-logo">
    <img
      src="https://t3.ftcdn.net/jpg/08/26/73/96/240_F_826739650_jtZ9s2ledfN2NRAepbbFNynMf6T4fGao.jpg"
      alt="SAP Logo"
      className="sap55-logo-img"
    />
  </div>
  <div className="sap55-text-content">
    <p className="sap55-text">
      Unlock the full potential of your SAP investment with Devon Technologies. Our experts will guide you through every step to make your SAP implementation successful and derive the expected business benefits through your digital transformation.
    </p>
    <p className="sap55-text">
      As a trusted SAP solutions provider with over 2 decades of experience, we understand the significance of combining technology with human-centricity. Our commitment to delivering 100% unique and tailored solutions guarantees that your business harnesses the full potential of SAP software, leading to unparalleled growth and success.
    </p>
  </div>
</div>


      {/* SAP Section */}
      <div className="sap55-sap-section">
        <div className="sap55-sap-text">
          <h2 className="sap55-sap-heading">SAP</h2>
          <p className="sap55-sap-description">
            SAP is a fully integrated software solution that helps enterprises of all sizes manage their business operations, intelligence, decision-making, and digitization. SAP’s flagship enterprise resource planning (ERP) software products, SAP ERP Central Component (ECC), and S/4HANA have become the standard for managing and executing business strategy, with 77% of the world’s transaction revenue occurring through SAP.
          </p>
        </div>
        <div className="sap55-sap-image">
          <img
            src="https://t4.ftcdn.net/jpg/07/73/07/63/240_F_773076333_ybKg6549rqzfyUDlJz3WdE0ddXIR2sqn.jpg"
            alt="SAP Image"
            className="sap55-sap-img"
          />
        </div>
      </div>

      {/* Implementation Services Section */}
      <div className="sap55-implementation-section">
        <h2 className="sap55-implementation-heading">
          Automatic’s <span className="sap55-blue">SAP Implementation Services</span>
        </h2>
        <div className="sap55-card-container">
          <div className="sap55-card">
            <img
              src="https://t3.ftcdn.net/jpg/07/79/46/32/240_F_779463205_kNcKmeqfrReBsQAF4lR55jKst8FJENOd.jpg"
              alt="Scalability"
              className="sap55-card-img"
            />
            <div className="sap55-card-text">
              <h3 className="sap55-card-heading">Scalability, Transparency, and Security</h3>
              <p className="sap55-card-description">
                Achieve greater efficiency with scalable infrastructure, transparent processes, and robust data security provided by SAP software.
              </p>
            </div>
          </div>
          <div className="sap55-card">
            <img
              src="https://images.pexels.com/photos/1203658/pexels-photo-1203658.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt="Greenfield"
              className="sap55-card-img"
            />
            <div className="sap55-card-text">
              <h3 className="sap55-card-heading">Greenfield / Brownfield</h3>
              <p className="sap55-card-description">
                Whether you're an existing SAP user or new to SAP solutions, we offer tailored implementation approaches to suit your needs.
              </p>
            </div>
          </div>
          <div className="sap55-card">
            <img
              src="https://images.pexels.com/photos/747964/pexels-photo-747964.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt="Implementation"
              className="sap55-card-img"
            />
            <div className="sap55-card-text">
              <h3 className="sap55-card-heading">SAP Implementation with Devon Technologies</h3>
              <p className="sap55-card-description">
                Our expert teams help you navigate SAP technologies and solutions to implement individual modules or complex SAP S/4HANA systems effectively.
              </p>
            </div>
          </div>
        </div>
      </div>

 {/* Expert Teams Section */}
<div className="sap55-expert-teams">
  <h2 className="sap55-expert-heading">
    Our expert teams help you navigate SAP technologies and solutions to implement individual modules or complex SAP S/4HANA systems effectively.
  </h2>
  
  {/* Expert Cards Section */}
  <div className="sap55-expert-cards-wrapper">
    <button className="sap55-prev-btn">&lt;</button>
    <div className="sap55-expert-cards">
      {/* Card 1 - SAP Best Practices */}
      <div className="sap55-expert-card sap55-card1">
        <img
          src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/5.jpg"
          alt="SAP Best Practices"
          className="sap55-expert-card-img"
        />
        <div className="sap55-expert-card-text">
          <h3 className="sap55-expert-card-heading">SAP Best Practices</h3>
          <p className="sap55-expert-card-description">
            Deployment of SAP best practices. Working closely with the Stakeholders and decision makers to deploy proven SAP project methodologies.
          </p>
        </div>
      </div>

      {/* Card 2 - Greater Efficiency */}
      <div className="sap55-expert-card sap55-card2">
        <img
          src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/1.jpg"
          alt="Greater Efficiency"
          className="sap55-expert-card-img"
        />
        <div className="sap55-expert-card-text">
          <h3 className="sap55-expert-card-heading">Greater Efficiency</h3>
          <p className="sap55-expert-card-description">
            Automate mundane tasks, reduce administrative costs, and improve operational efficiency.
          </p>
        </div>
      </div>

      {/* Card 3 - Scalability and Customization */}
      <div className="sap55-expert-card sap55-card3">
        <img
          src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/5.jpg"
          alt="Scalability and Customization"
          className="sap55-expert-card-img"
        />
        <div className="sap55-expert-card-text">
          <h3 className="sap55-expert-card-heading">Scalability and Customization</h3>
          <p className="sap55-expert-card-description">
            SAP solutions grow with your company, offering flexibility and rapid adaptation to your evolving needs.
          </p>
        </div>
      </div>

      {/* Card 4 - Advanced Reporting */}
      <div className="sap55-expert-card sap55-card4">
        <img
          src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/3.jpg"
          alt="Advanced Reporting"
          className="sap55-expert-card-img"
        />
        <div className="sap55-expert-card-text">
          <h3 className="sap55-expert-card-heading">Advanced Reporting</h3>
          <p className="sap55-expert-card-description">
            Make informed decisions and accurate predictions with advanced SAP reporting tools.
          </p>
        </div>
      </div>

      {/* Card 5 - Enhanced Data Security */}
      <div className="sap55-expert-card sap55-card5">
        <img
          src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/4.jpg"
          alt="Enhanced Data Security"
          className="sap55-expert-card-img"
        />
        <div className="sap55-expert-card-text">
          <h3 className="sap55-expert-card-heading">Enhanced Data Security</h3>
          <p className="sap55-expert-card-description">
            Ensure the security and accuracy of all company data with SAP solutions.
          </p>
        </div>
      </div>

      {/* Card 6 - Performance Improvements */}
      <div className="sap55-expert-card sap55-card6">
        <img
          src="https://www.automaticinfotech.com/wp-content/uploads/2024/04/6.jpg"
          alt="Performance Improvements"
          className="sap55-expert-card-img"
        />
        <div className="sap55-expert-card-text">
          <h3 className="sap55-expert-card-heading">Performance Improvements</h3>
          <p className="sap55-expert-card-description">
            Experience significant increases in income, sales, and business process automation.
          </p>
        </div>
      </div>
    </div>
    <button className="sap55-next-btn">&gt;</button>
  </div>
</div>



      {/* SAP Methodologies Section */}
      <div className="sap55-methodologies-section">
        <div className="sap55-methodologies-img">
          <img
            src="https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&w=1600"
            alt="Methodologies"
            className="sap55-methodologies-img"
          />
        </div>
        <div className="sap55-methodologies-text">
          <h2 className="sap55-methodologies-heading">
            SAP <span className="sap55-blue">IMPLEMENTATION METHODOLOGIES</span>
          </h2>
          <p className="sap55-methodologies-description">
            At Devon Technologies, we leverage SAP best practice methodologies such as SAP Activate and ASAP (Accelerated SAP) to drive efficient and effective SAP implementation projects.
          </p>
          <p className="sap55-methodologies-description">
            SAP Activate offers a structured framework with pre-built solutions, best practices, and configuration tools, enabling us to expedite implementation and minimize risks across on premise, cloud, or hybrid environments. Complemented by the phased approach of ASAP methodology, we ensure rapid deployment and value realization, maintaining focus on quality and customer satisfaction. Additionally, our ability to tailor a hybrid approach allows us to address the specific needs of each organization, maximizing the benefits of SAP solutions while minimizing disruptions to business operations. Through these methodologies, we accelerate the development and implementation of SAP solutions, delivering tangible business value and driving our clients’ success.
          </p>
          <a href="#contact" className="sap55-contact-link">
            Contact Devon Technologies
          </a>
        </div>
       
      </div>
      <Lower/>
    </div>
  );
};

export default Saptrans;
