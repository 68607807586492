import React, { useState } from 'react';
import './TransformationStyle.css';

const Transformation = () => {
  const [activeCard, setActiveCard] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveCard(index);
  };

  const handleMouseLeave = () => {
    setActiveCard(0);
  };

  const navigateToPage = (path) => {
    window.location.href = path;
  };

  return (
    <div className="transformation-containerTrans">
      <h1 className="heading-mainTrans">
        <span className="black-textTrans">Begin Your </span>
        <span className="blue-textTrans">Digital Transformation </span>
        <span className="black-textTrans">Journey with</span>
      </h1>
      <h2 className="heading-subTrans">
        <span className="black-textTrans">Devon Technology</span>
      </h2>
      
      <div className="card-containerTrans">
        <div 
          className={`cardTrans ${activeCard === 1 ? 'activeTrans' : ''}`} 
          onMouseEnter={() => handleMouseEnter(1)} 
          onMouseLeave={handleMouseLeave}
        >
          <img src="https://img.freepik.com/premium-photo/sap-system-software-automation-concept-motherboard_438099-9347.jpg" alt="SAP Transformation" className="card-imageTrans" />
          <h3 className="card-titleTrans">SAP Transformation</h3>
          <button className="know-more-btnTrans" onClick={() => navigateToPage('/Saptrans')}>Know More</button>
        </div>

        <div 
          className={`cardTrans ${activeCard === 2 ? 'activeTrans' : ''}`} 
          onMouseEnter={() => handleMouseEnter(2)} 
          onMouseLeave={handleMouseLeave}
        >
          <img src="https://img.freepik.com/free-vector/digital-cloud-pattern-background-connection-technology_53876-119493.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid" alt="Cloud Engineering" className="card-imageTrans" />
          <h3 className="card-titleTrans">Cloud Engineering</h3>
          <button className="know-more-btnTrans" onClick={() => navigateToPage('/Cloud')}>Know More</button>
        </div>

        <div 
          className={`cardTrans ${activeCard === 3 ? 'activeTrans' : ''}`} 
          onMouseEnter={() => handleMouseEnter(3)} 
          onMouseLeave={handleMouseLeave}
        >
          <img src="https://www.forest-interactive.com/wp-content/uploads/2020/11/4-1024x667.jpg" alt="Application" className="card-imageTrans" />
          <h3 className="card-titleTrans">Application</h3>
          <button className="know-more-btnTrans" onClick={() => navigateToPage('/Application')}>Know More</button>
        </div>
      </div>
    </div>
  );
};

export default Transformation;
