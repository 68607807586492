import React from 'react';
import './FullstackStyle.css';

const Fullstack = () => {
  return (
    <div className="fullstack-container-stack">
      {/* Section 1: Introduction */}
      <div className="section1-stack">
        <div className="image-container1-stack">
          <img
            src="https://c0.wallpaperflare.com/preview/655/944/913/computer-keyboard-technology-work.jpg"
            alt="Full-Stack Development"
            className="image1-stack"
          />
        </div>
        <div className="text-container1-stack">
          <h1 className="title1-stack">
            End-to-End <span className="blue-stack">Full-Stack Development</span> Solutions
          </h1>
          <p className="description1-stack">
            At Devon Technologies, we provide comprehensive full-stack development services that cover both front-end and back-end technologies. Our team of experienced developers ensures seamless integration and robust solutions tailored to your business needs.
          </p>
          <p className="additional-info1-stack">
            From designing intuitive user interfaces to developing scalable server-side applications, we handle all aspects of full-stack development to deliver high-quality, end-to-end solutions.
          </p>
        </div>
      </div>

      {/* Section 2: Our Approach */}
      <div className="section2-stack">
        <div className="text-container2-stack">
          <h2 className="sub-title2-stack">
            Our <span className="blue-stack">Approach</span> to Full-Stack Development
          </h2>
          <p className="description2-stack">
            We follow a holistic approach to full-stack development, starting with a deep understanding of your requirements. Our methodology involves iterative design, continuous feedback, and agile practices to ensure that the final product meets your expectations and business goals.
          </p>
          <p className="additional-info2-stack">
            Our team uses cutting-edge technologies and frameworks to build scalable and maintainable applications. We focus on delivering user-centric solutions that provide a seamless experience across all devices and platforms.
          </p>
        </div>
        <div className="image-container2-stack">
          <img
            src="https://c0.wallpaperflare.com/preview/104/672/906/abstract-php-c-analytics.jpg"
            alt="Our Approach"
            className="image2-stack"
          />
        </div>
      </div>

      {/* Section 3: Technologies We Use */}
      <div className="section3-stack">
        <div className="text-container3-stack">
          <h2 className="sub-title3-stack">
            Technologies We <span className="blue-stack">Use</span>
          </h2>
          <p className="description3-stack">
            Our full-stack development services utilize a wide range of technologies to build powerful and efficient applications. From modern front-end frameworks to robust back-end solutions, we ensure that your project benefits from the best tools available.
          </p>
          <ul className="tech-list-stack">
            <li className="tech-item-stack">React.js</li>
            <li className="tech-item-stack">Node.js</li>
            <li className="tech-item-stack">Express.js</li>
            <li className="tech-item-stack">MongoDB</li>
            <li className="tech-item-stack">AWS</li>
          </ul>
        </div>
        <div className="image-container3-stack">
          <img
            src="https://images.pexels.com/photos/3861969/pexels-photo-3861969.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Technologies"
            className="image3-stack"
          />
        </div>
      </div>

      {/* Section 4: Success Stories */}
      <div className="section4-stack">
        <div className="image-container4-stack">
          <img
            src="https://images.pexels.com/photos/1181355/pexels-photo-1181355.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Success Stories"
            className="image4-stack"
          />
        </div>
        <div className="text-container4-stack">
          <h2 className="sub-title4-stack">
            Our <span className="blue-stack">Success Stories</span>
          </h2>
          <p className="description4-stack">
            We take pride in our successful full-stack projects that have driven significant business outcomes for our clients. Our portfolio showcases a diverse range of applications, each demonstrating our commitment to excellence and innovation.
          </p>
          <p className="additional-info4-stack">
            Explore our case studies to see how we have transformed businesses with our full-stack development solutions. From startups to established enterprises, we have delivered impactful results across various industries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fullstack;
