import React from 'react';
import './TestingStyle.css';

const Testing = () => {
  return (
    <div className="testing-container-test">
      {/* Section 1: Introduction */}
      <div className="section1-test">
        <div className="image-container1-test">
          <img
            src="https://images.pexels.com/photos/3183153/pexels-photo-3183153.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Software Testing"
            className="image1-test"
          />
        </div>
        <div className="text-container1-test">
          <h1 className="title1-test">
            Comprehensive <span className="bluetest">Software Testing</span> Solutions
          </h1>
          <p className="description1-test">
            At Devon Technologies, we offer a full range of software testing services designed to ensure the quality, performance, and security of your applications. Our expert team uses the latest testing methodologies to identify and fix issues before they impact your business operations.
          </p>
          <p className="additional-info1-test">
            From automated testing to manual testing, we cover every aspect of the software development lifecycle, providing you with the confidence that your software will perform as expected in real-world scenarios.
          </p>
        </div>
      </div>

      {/* Section 2: Testing Services */}
      <div className="section2-test">
        <div className="text-container2-test">
          <h2 className="sub-title2-test">
            Tailored <span className="bluetest">Testing Services</span> for Your Needs
          </h2>
          <p className="description2-test">
            Our testing services are customized to meet the specific requirements of your projects. Whether you need functional testing, performance testing, or security testing, our team delivers thorough and reliable results to ensure the highest quality of your software.
          </p>
          <p className="additional-info2-test">
            We use industry-leading tools and frameworks to execute comprehensive test plans, identify potential issues, and provide detailed reports that help you improve your software quality.
          </p>
        </div>
        <div className="image-container2-test">
          <img
            src="https://img.freepik.com/premium-photo/photorealistic-3d-mockup-modern-office-desk-ready-software-testing-tester-with-focused-expression-scrutinizes-code-editor-desktop-computer-screen-smartphone-displaying_998587-3089.jpg?w=740"
            alt="Testing Services"
            className="image2-test"
          />
        </div>
      </div>

      {/* Section 3: Why Testing Matters? */}
      <div className="section3-test">
        <div className="text-container3-test">
          <h2 className="sub-title3-test">
            Why <span className="bluetest">Testing</span> is Critical for Your Software?
          </h2>
          <p className="description3-test">
            Software testing is essential for identifying defects and vulnerabilities that could cause your applications to fail. It ensures that your software meets user expectations, complies with regulatory requirements, and operates reliably under all conditions.
          </p>
          <p className="additional-info3-test">
            By investing in thorough testing, you can reduce the risk of costly post-release issues, improve customer satisfaction, and maintain a strong reputation in the market.
          </p>
        </div>
        <div className="image-container3-test">
          <img
            src="https://images.pexels.com/photos/3861969/pexels-photo-3861969.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Importance of Testing"
            className="image3-test"
          />
        </div>
      </div>

      {/* Section 4: Our Testing Expertise */}
      <div className="section4-test">
        <div className="image-container4-test">
          <img
            src="https://img.freepik.com/free-vector/new-app-development-desktop_23-2148684987.jpg?t=st=1724308185~exp=1724311785~hmac=fdbe2a34d8340a1496dfe21ea22a12a46f8135e9c63d0fc3e24db5b170b3e074&w=740"
            alt="Testing Expertise"
            className="image4-test"
          />
        </div>
        <div className="text-container4-test">
          <h2 className="sub-title4-test">
            Our <span className="bluetest">Testing Expertise</span>
          </h2>
          <p className="description4-test">
            With years of experience in software testing, our team has the expertise to deliver high-quality testing solutions across various industries. We are proficient in the latest testing tools and practices, ensuring that your software is thoroughly tested and ready for deployment.
          </p>
          <p className="additional-info4-test">
            Our approach to testing is comprehensive, covering everything from test planning and design to execution and reporting. Trust Devon Technologies to provide the testing expertise you need to achieve flawless software delivery.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testing;
