import React from 'react'
import "./LowerStyle.css"

const Lower = () => {
  return (
    <div>
         {/* Consultation Section */}
         <div className="sap55-consultation-section">
        <img
          src="https://images.pexels.com/photos/276014/pexels-photo-276014.jpeg?auto=compress&cs=tinysrgb&w=1600"
          alt="Consultation"
          className="sap55-consultation-img"
        />
        <div className="sap55-consultation-text">
          <h2 className="sap55-consultation-heading">
            REGISTER FOR A FREE CONSULTATION AND UNLEASH YOUR ENTERPRISE’S FULL POTENTIAL
          </h2>
          <p className="sap55-consultation-subheading">
            Experience Intelligent Enterprise
          </p>
          <a href="#start" className="sap55-consultation-link">
            Begin Your Transformation Journey Now
          </a>
        </div>
      </div>
    </div>
  )
}

export default Lower
