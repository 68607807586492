import React from 'react'



import TrustSol from './TrustSol'
import Transformation from './Transformation'
import Services from './Services'
import About from './About'


const Homepage = () => {
  return (
    <div>
   

  <TrustSol/>
  <Transformation/>
  <Services/>
  <About/>
  
   
    </div>
  )
}

export default Homepage
